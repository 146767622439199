import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  debounce,
  Avatar,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material"
import { Search, CloseOutlined } from "@mui/icons-material"

import * as GraphQL from "../../../../graphql"
import * as API from "../../../../util/apiClient"
import { DEFAULT_DEBOUNCE_WAIT } from "../../../../util/constant"
import Input from "../../../Input"
import LoadingIndicator from "../../../LoadingIndicator"
import "./user-filter-list.sass"

type UserType = GraphQL.CustomerTeamMemberFragment

interface Props {
  setSelectedUsers: (users: UserType[]) => void
  selectedUsers: UserType[]
  customerId?: string
}

function UserFilterList({
  setSelectedUsers, selectedUsers, customerId,
}: Props) {
  const [ users, setUsers ] = useState<UserType[]>([])
  const [ filteredUsers, setFilteredUsers ] = useState<UserType[]>([])
  const [ usersLoading, setUsersLoading ] = useState(false)
  const [ filterValue, setFilterValue ] = useState("")
  const { t: translate } = useTranslation([], { keyPrefix: "component.AssignReviewersModal" })

  const updateFilteredUsers = (allUsers: UserType[], filter: string) => {
    const filtered = allUsers.filter((user) => user.contact.name.toLowerCase().includes(filter.toLowerCase())
      && !selectedUsers.some((selectedUser) => selectedUser.id === user.id))
    setFilteredUsers(filtered)
  }

  const searchUsers = async (startsWith: string) => {
    if (!customerId) return
    const result = await API.searchTeamMembersForCustomer({
      startsWith,
      page: 1,
      limit: 5000,
      customerId,
    })

    if (API.isSuccess(result)) {
      setUsers(result.payload.searchUsers.rows)
      updateFilteredUsers(result.payload.searchUsers.rows, startsWith)
    } else {
      setUsers([])
      setFilteredUsers([])
    }
  }

  const debouncedSearchUsers = debounce(async (inputValue: string) => {
    setUsersLoading(true)
    await searchUsers(inputValue)
    setUsersLoading(false)
  }, DEFAULT_DEBOUNCE_WAIT)

  const resetData = () => {
    setUsers([])
    setFilteredUsers([])
    setSelectedUsers([])
    setFilterValue("")
    setUsersLoading(false)
  }

  useEffect(() => {
    const fetchUsers = async () => {
      if (customerId) {
        setSelectedUsers([])
        setUsersLoading(true)
        await searchUsers("")
        setUsersLoading(false)
      } else {
        resetData()
      }
    }
    fetchUsers()
  }, [ customerId ])

  useEffect(() => {
    updateFilteredUsers(users, filterValue)
  }, [ selectedUsers, users, filterValue ])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFilterValue(value)
    debouncedSearchUsers(value)
  }

  const handleUserToggle = (user: UserType) => {
    const newSelectedUsers = selectedUsers.some((selectedUser) => selectedUser.id === user.id)
      ? selectedUsers.filter((selectedUser) => selectedUser.id !== user.id)
      : [ ...selectedUsers, user ]
    setSelectedUsers(newSelectedUsers)
  }

  const renderUserItem = (user: UserType, isSelected: boolean) => (
    <ListItem
      key={ user.id }
      secondaryAction={ (
        <IconButton
          edge="end"
          aria-label="remove"
          onClick={ () => handleUserToggle(user) }
          className="cp_component-user-filter-list_remove-button"
        >
          <CloseOutlined />
        </IconButton>
      ) }
      disablePadding={ true }
      className="cp_component-user-filter-list_item"
    >
      <Checkbox
        edge="start"
        checked={ isSelected }
        tabIndex={ -1 }
        disableRipple={ true }
        onChange={ () => handleUserToggle(user) }
        className="cp_component-user-filter-list_checkbox"
      />
      <ListItemAvatar>
        <Avatar
          src={ user.contact.avatar.url.address }
          alt={ user.contact.name }
          className="cp_component-user-filter-list_avatar"
        >
          { user.contact.name.charAt(0) }
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={ (
          <Typography variant="subtitle1" className="cp_component-user-filter-list_user-name">
            { user.contact.name }
          </Typography>
        ) }
      />
    </ListItem>
  )

  return (
    <div className="cp_component-user-filter-list">
      <Input
        fullWidth={ true }
        placeholder={ translate("Filter by User") }
        value={ filterValue }
        onChange={ handleInputChange }
        InputProps={ {
          startAdornment: <Search />,
        } }
        id="user-autocomplete"
        className="cp_component-user-filter-list_input"
      />
      { usersLoading ? (
        <div className="cp_component-user-filter-list_loading">
          <LoadingIndicator size={ 50 } />
        </div>
      ) : (
        (selectedUsers.length > 0 || filteredUsers.length > 0) && (
        <List className="cp_component-user-filter-list_list">
          { selectedUsers.map((user) => renderUserItem(user, true)) }
          { filteredUsers.map((user) => renderUserItem(user, false)) }
        </List>
        )
      ) }
    </div>
  )
}

export default UserFilterList
