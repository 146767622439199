import React, { JSX } from "react"
import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

import TargetFilter from "./PromptAI"
import NetworkFilter from "./NetworkFilter"

import "./style.sass"

export default function AISearchFilter(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.AISearchFilter" })

  return (
    <Stack id="cp_component_ai-search-filter" padding={ 2 } className="cp_component_ai-search-filter" gap={ 2 }>
      <Typography fontSize="1rem" fontWeight={ 700 }>
        { translate("Quick Filters") }
      </Typography>
      <TargetFilter />
      <NetworkFilter />
    </Stack>
  )
}
