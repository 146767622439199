import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"
import * as API from "../../util/apiClient"

type SocialAvatarProps = {
  id: string
  followers: number,
  fullName: string,
  isBlacklisted: boolean,
  isNSFW: boolean,
  profilePictureUrl: string,
  username: string,
  isPlaceholder: boolean,
  isUnsubscribed: boolean,
  network: GraphQL.Network,
}

export type ContactModalProps = {
  firstName: string
  lastName: string
  websiteUrl: string
  emails: { __typename?: "NetworkEmail", address: string }[]
  emailsFromTeam: { __typename?: "Email", id: string, address: string }[]
  primaryEmail: string
  emailsToBeSaved?: { __typename?: "Email", id: string, address: string }[]
  emailsToBeDeleted?: { __typename?: "Email", id: string, address: string }[]
}

export interface ContactInfoModalState {
  GAContactInfoSocialAvatarInfo: SocialAvatarProps
  GASocialAccountId: string
  GAContactInfoModalOpen: boolean
  GAContactInfo: Status<GraphQL.GetGroupAccountContactInfoForEditQuery>
  GAContactInfoUpdated: Status<GraphQL.EditGroupAccountContactInformationMutation | null>
  GAPrimaryEmailUpdated: Status<GraphQL.EditGroupAccountPrimaryEmailAddressMutation | null>
  GAEditEmailFromTeamModalOpen: boolean
  ContactInfoModalData: ContactModalProps
}

const initialState: ContactInfoModalState = {
  GAContactInfoSocialAvatarInfo: {
    followers: 0,
    fullName: "Full Name",
    profilePictureUrl: "",
    username: "Username",
    id: "",
    isBlacklisted: false,
    isNSFW: false,
    isPlaceholder: false,
    isUnsubscribed: false,
    network: GraphQL.Network.Facebook,
  },
  GASocialAccountId: "",
  GAContactInfoModalOpen: false,
  GAContactInfo: "init",
  GAContactInfoUpdated: "init",
  GAPrimaryEmailUpdated: "init",
  GAEditEmailFromTeamModalOpen: false,
  ContactInfoModalData: {
    firstName: "",
    lastName: "",
    websiteUrl: "",
    emails: [],
    emailsFromTeam: [],
    primaryEmail: "",
    emailsToBeSaved: [],
    emailsToBeDeleted: [],
  },
}

export const ContactInfoModalSlice = createSlice({
  name: "ContactInfoModal",
  initialState,
  reducers: {
    setSocialAvatarInfo: (
      state,
      action: PayloadAction<SocialAvatarProps>,
    ) => ({
      ...state,
      GAContactInfoSocialAvatarInfo: action.payload,
    }),
    setSocialAccountId: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      GASocialAccountId: action.payload,
    }),
    setContactInfoModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      GAContactInfoModalOpen: action.payload,
    }),
    setEmailFromTeamModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      GAEditEmailFromTeamModalOpen: action.payload,
    }),
    setContactInfo: (
      state,
      action: PayloadAction<Status<GraphQL.GetGroupAccountContactInfoForEditQuery>>,
    ) => ({
      ...state,
      GAContactInfo: action.payload,
    }),
    setUpdatedContactInfo: (
      state,
      action: PayloadAction<Status<GraphQL.EditGroupAccountContactInformationMutation | null>>,
    ) => ({
      ...state,
      GAContactInfoUpdated: action.payload,
    }),
    setUpdatedPrimaryEmail: (
      state,
      action: PayloadAction<Status<GraphQL.EditGroupAccountPrimaryEmailAddressMutation | null>>,
    ) => ({
      ...state,
      GAPrimaryEmailUpdated: action.payload,
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetContactInfoModal: (state) => initialState,
    setContactInfoModalData: (
      state,
      action: PayloadAction<ContactModalProps>,
    ) => ({
      ...state,
      ContactInfoModalData: action.payload,
    }),
  },
})

export const {
  setSocialAvatarInfo,
  setSocialAccountId,
  setContactInfoModalOpen,
  setContactInfo,
  setUpdatedContactInfo,
  setUpdatedPrimaryEmail,
  resetContactInfoModal,
  setEmailFromTeamModalOpen,
  setContactInfoModalData,
} = ContactInfoModalSlice.actions

export default ContactInfoModalSlice.reducer

/**
 * Query to get the contact information to fill in the form
 * @param socialAccountId The ID for the social account
 * @returns The required information for the social account contact info edit form
 */
export const getGroupAccountContactInfo = (
  socialAccountId: string,
  emailsToBeSaved: { __typename?: "Email", id: string, address: string }[],
  emailsToBeDeleted: { __typename?: "Email", id: string, address: string }[],
) => async (dispatch: Dispatch) => {
  // Indicate loading status
  dispatch(setContactInfo("loading"))

  // Perform the query
  const results = await API.getGroupAccountContactInfoForEdit({
    socialAccountId,
  })

  // Check results
  if (API.isSuccess(results)) {
    // Set database value for emails
    let efromt = results.payload.socialAccount.emailsSourcedFromTeam

    // Remove all emails that are being deleted
    if (emailsToBeDeleted && emailsToBeDeleted.length > 0) {
      efromt = efromt.filter((email) => !emailsToBeDeleted.find((demail) => demail.id === email.id))
    }

    // Add all the emails that are not already there
    if (emailsToBeSaved && emailsToBeSaved.length > 0) efromt.push(...emailsToBeSaved)

    // Create the modal data
    const modalData: ContactModalProps = {
      firstName: (results.payload.socialAccount.contact?.firstName) ? results.payload.socialAccount.contact?.firstName : "",
      lastName: (results.payload.socialAccount.contact?.lastName) ? results.payload.socialAccount.contact?.lastName : "",
      websiteUrl: (results.payload.socialAccount.websiteUrl) ? results.payload.socialAccount.websiteUrl : "",
      emails: results.payload.socialAccount.emails,
      emailsFromTeam: efromt,
      primaryEmail: (results.payload.socialAccount.primaryEmail?.address)
        ? results.payload.socialAccount.primaryEmail?.address : "",
      emailsToBeSaved,
      emailsToBeDeleted,
    }

    // Save to state
    dispatch(setContactInfoModalData(modalData))
  }

  // Post results
  dispatch(setContactInfo(results))
}

export const updateContactInformationForAccount = (
  params: {
    socialAccountId: string,
    data: ContactModalProps,
    onSuccess: () => void,
    onWarning: () => void,
    onError: () => void,
  },
) => async (dispatch: Dispatch) => {
  // Set the state
  let success = true
  let failed = false
  let warning = false
  dispatch(setUpdatedContactInfo("loading"))

  // Determine if updating name
  if (params.data.firstName !== "" && params.data.lastName !== "") {
    // Create data for mutation call
    const ciData: GraphQL.EditGroupAccountContactInformationMutationVariables = {
      socialAccountId: params.socialAccountId,
      firstName: params.data.firstName,
      lastName: params.data.lastName,
    }

    // Make call to GraphQL to update the name
    const results = await API.updateSocialAccountContactInfo(ciData)

    // Set failed and success value
    failed = API.isError(results)
    success = !failed
  } else if (params.data.firstName !== "" || params.data.lastName !== "") warning = true

  // Determine if updating primary email address
  if (params.data.primaryEmail !== "") {
    // Create data for mutation call
    const ciPrimaryEmailData: GraphQL.EditGroupAccountPrimaryEmailAddressMutationVariables = {
      socialAccountId: params.socialAccountId,
      email: params.data.primaryEmail,
    }

    // Make call to GraphQL to update email
    const results = await API.updateSocialAccountPrimaryEmail(ciPrimaryEmailData)

    // Set failed and success value
    failed = (failed) ? true : API.isError(results)
    success = !failed
  }

  // Save any new email addresses
  if (params.data.emailsToBeSaved && params.data.emailsToBeSaved.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const email of params.data.emailsToBeSaved) {
      // Create required variables for mutation call
      const vars: GraphQL.CreateSocialAccountEmailMutationVariables = {
        networkAccountId: params.socialAccountId,
        emailAddress: email.address,
      }

      // Call the function
      // eslint-disable-next-line no-await-in-loop
      const results = await API.createSocialAccountEmail(vars)

      // Check to see if this failed or was successful
      failed = (failed) ? true : API.isError(results)
      success = !failed
    }
  }

  // Delete any emails to be deleted
  if (params.data.emailsToBeDeleted && params.data.emailsToBeDeleted.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const email of params.data.emailsToBeDeleted) {
      // Create required variables for mutation call
      const vars: GraphQL.DeleteSocialAccountEmailMutationVariables = {
        networkAccountId: params.socialAccountId,
        emailId: email.id,
      }

      // Call the function
      // eslint-disable-next-line no-await-in-loop
      const results = await API.deleteSocialAccountEmail(vars)

      // Check results
      failed = (failed) ? true : API.isError(results)
      success = !failed
    }
  }

  // Call callbacks
  if (success) params.onSuccess()
  if (warning) params.onWarning()
  if (failed) params.onError()
}

/**
 * This will update the social account contact information in the database
 * @param params The mutation variables required for update along with succes and failure callbacks
 * @returns void
 */
export const updateGroupAccountContactInfo = (
  params: {
    variables: GraphQL.EditGroupAccountContactInformationMutationVariables,
    onSuccess: () => void,
    onError: () => void,
  },
) => async (dispatch: Dispatch) => {
  // Set the state
  dispatch(setUpdatedContactInfo("loading"))

  // Perform mutation
  const results = await API.updateSocialAccountContactInfo(params.variables)

  if (API.isSuccess(results)) params.onSuccess()
  else if (API.isError(results)) params.onError()

  // Set the results
  dispatch(setUpdatedContactInfo(results))
}

/**
 * Updates the database primary email address for a social account
 * @param params The primary email address and callbacks for successful or error
 * @returns void
 */
export const updateGroupAccountPrimaryEmail = (
  params: {
    variables: GraphQL.EditGroupAccountPrimaryEmailAddressMutationVariables,
    onSuccess: () => void,
    onError: () => void,
  },
) => async (dispatch: Dispatch) => {
  // Indicate that the process of update has started
  dispatch(setUpdatedPrimaryEmail("loading"))

  // Call mutation function to update the email
  const results = await API.updateSocialAccountPrimaryEmail(params.variables)

  // Determine if a success update occurred
  if (API.isSuccess(results)) params.onSuccess()
  else if (API.isError(results)) params.onError()

  // Indicate that the results are complete
  dispatch(setUpdatedPrimaryEmail(results))
}

/**
 * Adds a new social account email address to the database
 * @param params The social account id, email, onSuccess callback and onError callback
 * @returns void
 */
export const updateEmailAddedByTeam = (
  params: {
    socialAccountId: string,
    emails: string[],
    onSuccess: () => void,
    onError: () => void,
  },
) => async () => {
  // Loop through emails and save them
  let failed = false
  // eslint-disable-next-line no-restricted-syntax
  for (const email of params.emails) {
    // Create required variables for mutation call
    const vars: GraphQL.CreateSocialAccountEmailMutationVariables = {
      networkAccountId: params.socialAccountId,
      emailAddress: email,
    }

    // Call the function
    // eslint-disable-next-line no-await-in-loop
    const results = await API.createSocialAccountEmail(vars)

    // Check to see if this failed or was successful
    failed = API.isError(results)
    if (failed) break
  }

  // Display success or failure
  if (!failed) params.onSuccess()
  else params.onError()
}

/**
 * This will delete 1 or more emails from the database
 * @param params Social Account ID, emails to delete, and success/error callbacks
 * @returns void
 */
export const deleteEmailsAddedByTeam = (
  params: {
    socialAccountId: string,
    emails: GraphQL.Email[],
    onSuccess: () => void,
    onError: () => void,
  },
) => async () => {
  // Loop though each email to delete
  let failed = false
  // eslint-disable-next-line no-restricted-syntax
  for (const email of params.emails) {
    // Create required variables for mutation call
    const vars: GraphQL.DeleteSocialAccountEmailMutationVariables = {
      networkAccountId: params.socialAccountId,
      emailId: email.id,
    }

    // Call the function
    // eslint-disable-next-line no-await-in-loop
    const results = await API.deleteSocialAccountEmail(vars)

    // Check results
    failed = API.isError(results)
    if (failed) break
  }

  // Check failed indicator and display success or failure message
  if (!failed) params.onSuccess()
  else params.onError()
}
