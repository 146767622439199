import React, { useMemo } from "react"
import { UploadFileRounded } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { Container } from "@mui/material"
import { useDispatch, useSelector } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import CommunicationMessagesEmpty from "./CommunicationMessagesEmpty"
import { CommMessage, ConversationUploads } from "./communicationMessage"

import "./communications-reading-panel-view.sass"
import { Scope } from "../../util/types"
import { openModalCreateDeliverable } from "../../state/ModalCreateDeliverable"
import ErrorHandler from "../ErrorHandler"

interface ConversationData {
  hasMessages: boolean,
  messages: GraphQL.GetConversationQuery["getConversation"]["conversationThread"]["timeline"],
  subject: GraphQL.GetConversationQuery["getConversation"]["subject"],
  socialAccount: GraphQL.GetConversationQuery["getConversation"]["socialAccount"] | null,
}

export default function CommunicationsMessages() {
  const { communicationMessages } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)
  const { scopes } = useSelector(({ user }) => user)
  const dispatch = useDispatch()
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.CommunicationsMessages" })

  const mappedConversations = useMemo((): ConversationData => {
    if (API.isSuccess(communicationMessages)) {
      return {
        hasMessages: true,
        messages: communicationMessages.payload.getConversation.conversationThread.timeline,
        subject: communicationMessages.payload.getConversation.subject,
        socialAccount: communicationMessages.payload.getConversation.socialAccount,
      }
    }
    return {
      hasMessages: false,
      messages: [],
      subject: "",
      socialAccount: null,
    }
  }, [ communicationMessages ])

  if (communicationMessages === "loading") {
    return (
      <div className="communications-email-list-view loading">
        <LoadingIndicatorCard />
      </div>
    )
  }

  if (communicationMessages === "init" || mappedConversations.hasMessages === false) {
    return (
      <div id="cp_comm-messages-empty" className="communications-email-list-view">
        <CommunicationMessagesEmpty parentIdName="cp_comm-messages-empty" />
      </div>
    )
  }

  if (communicationMessages.status === "error") return <ErrorHandler />

  return (
    <div className="communications-email-list-view cp_communications-thread cp_ct">
      <div className="cp_ct-thread-header">
        { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) && (
          <Container
            id="cp_component_comms-messages_create-new-deliverable-button"
            onClick={ () => {
              const { snippets } = communicationMessages.payload.getConversation.conversationThread
              dispatch(openModalCreateDeliverable({ snippets }))
            } }
            className="create-new-deliverable-button"
          >
            <>
              <UploadFileRounded />
              <p>{ translate("Create New Deliverable") }</p>
            </>
          </Container>
        ) }
      </div>
      <h2 className="cp_ct-subject">{ mappedConversations.subject }</h2>
      { mappedConversations.hasMessages && (
        mappedConversations.messages
          .slice()
          .reverse()
          .map((message, indx) => {
            if (message.__typename === "ConversationMessage") {
              return (
                <CommMessage
                  key={ message.id }
                  message={ message }
                  socialAccount={ mappedConversations.socialAccount }
                  indx={ indx }
                  totalMessages={ mappedConversations.messages.length }
                />
              )
            }
            return (
              <ConversationUploads
                key={ message.uuid }
                message={ message }
                socialAccount={ mappedConversations.socialAccount }
                // NOTE: Doesn't seem like these props are used in the component? Going to comment out for now.
                // indx={ indx }
                // totalMessages={ mappedConversations.messages.length }
              />
            )
          })
      ) }
    </div>
  )
}
