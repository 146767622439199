import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SnippetFragment } from "../../graphql"

// Comms create template modal slice Interface and Initial State
export interface ModalCreateDeliverableState {
  open: boolean
  activeStep: number
  stepsCompleted: Array<number>
  deliverableTitle: string
  clientNote: string
  caption: string
  snippets: SnippetFragment[]
}

const initialState: ModalCreateDeliverableState = {
  open: false,
  activeStep: 1,
  stepsCompleted: [],
  deliverableTitle: "",
  clientNote: "",
  caption: "",
  snippets: [],
}

// Modal Create Deliverable Slice
export const modalCreateDeliverable = createSlice({
  name: "ModalCreateDeliverable",
  initialState,
  reducers: {
    openModalCreateDeliverable: (
      state,
      action: PayloadAction<{
        snippets: SnippetFragment[]
      }>,
    ) => ({
      ...state,
      open: true,
      snippets: action.payload.snippets,
    }),
    closeModalCreateDeliverable: (
      state,
    ) => ({
      ...state,
      open: false,
      activeStep: 1,
      deliverableTitle: "",
      clientNote: "",
    }),
    setActiveStep: (
      state,
      action: PayloadAction<number>,
    ) => ({
      ...state,
      activeStep: action.payload,
    }),
    setDeliverableTitle: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      deliverableTitle: action.payload,
    }),
    setClientNote: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      clientNote: action.payload,
    }),
    setCaption: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      caption: action.payload,
    }),
    pushStepCompleted: (
      state,
      action: PayloadAction<number>,
    ) => ({
      ...state,
      stepsCompleted: [ ...state.stepsCompleted, action.payload ],
    }),
    removeStepCompleted: (
      state,
      action: PayloadAction<number>,
    ) => ({
      ...state,
      stepsCompleted: state.stepsCompleted.filter((step) => step !== action.payload),
    }),
  },
})

export const {
  openModalCreateDeliverable,
  closeModalCreateDeliverable,
  setActiveStep,
  setDeliverableTitle,
  setClientNote,
  pushStepCompleted,
  removeStepCompleted,
  setCaption,
} = modalCreateDeliverable.actions
export default modalCreateDeliverable.reducer
