import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Grid } from "@mui/material"
import * as GraphQL from "../../../../graphql"
import { setModalOpen } from "../../../../state/assignReviewersModalSlice"
import { useDispatch, useSelector } from "../../../../state/hooks"
import Input from "../../../Input"
import LoadingIndicator from "../../../LoadingIndicator"
import Modal from "../../../Modal"
import PermissionsToggle from "./PermissionsToggle"
import CustomersAutocomplete from "./CustomersAutocomplete"
import UserAutocomplete from "./UserAutocomplete"
import "./campaign-assign-reviewers-modal.sass"
import { pushToast } from "../../../../state/toastSlice"

export default function AssignReviewersModal() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AssignReviewersModal" })
  const { t: translateCommon } = useTranslation([], { keyPrefix: "common" })
  const dispatch = useDispatch()

  const {
    modalOpen,
  } = useSelector(({ assignReviewersModal }) => assignReviewersModal)

  const [ groupName, setGroupName ] = useState("")
  const [ submitLoading, setSubmitLoading ] = useState(false)
  const [ selectedCustomer, setSelectedCustomer ] = useState<GraphQL.CustomerFragment | null>(null)
  const [ selectedUsers, setSelectedUsers ] = useState<GraphQL.CustomerTeamMemberFragment[]>([])

  const resetFields = () => {
    setGroupName("")
    setSelectedCustomer(null)
    setSelectedUsers([])
  }

  const onClose = () => {
    dispatch(setModalOpen(false))
    resetFields()
  }

  const onSubmit = async () => {
    setSubmitLoading(true)
    try {
      dispatch(pushToast({ message: translate("Successfully Created Group!"), type: "success" }))
      onClose()
    } catch (error) {
      dispatch(pushToast({ message: translate("Failed to create group"), type: "error" }))
    } finally {
      setSubmitLoading(false)
    }
  }

  return (
    <Modal
      className="cp_component_campaign-assign-reviewers-modal"
      open={ modalOpen }
      title={ translate("Create a New Reviewer Group") }
      subtitle={ translate(
        "Adds a group to the current campaign's approvals workflow. "
        + "Assign users and set their approval permissions before saving.",
      ) }
      primaryLabel={ submitLoading ? <LoadingIndicator /> : translate("Save") }
      secondaryLabel={ translateCommon("Cancel") }
      primaryAction={ onSubmit }
      secondaryAction={ onClose }
      closeAction={ onClose }
      maxWidth="xl"
    >
      <div className="add-reviewer-group-container">
        <Grid container={ true } spacing={ 2 }>
          <Grid item={ true } xs={ 12 } lg={ 6 }>
            <Input
              id="group-name"
              className="edit-group-name"
              label={ `${ translate("EDIT DEFAULT GROUP NAME") } ` }
              placeholder={ translate("Group [#] Reviewers") }
              value={ groupName }
              onChange={ (e) => setGroupName(e.target.value) }
            />
          </Grid>
          <Grid item={ true } xs={ 12 } lg={ 6 }>
            <p className="filter-by-customer">
              { translate("FILTER BY CUSTOMER") }
            </p>
            <CustomersAutocomplete
              selectedCustomer={ selectedCustomer }
              setSelectedCustomer={ setSelectedCustomer }
            />
          </Grid>
          <Grid item={ true } xs={ 12 } lg={ 6 }>
            <div className="user-filter-container">
              <UserAutocomplete
                setSelectedUsers={ setSelectedUsers }
                selectedUsers={ selectedUsers }
                customerId={ selectedCustomer?.id }
              />
            </div>
          </Grid>
          <Grid item={ true } xs={ 12 } lg={ 6 }>
            <div className="permissions-toggle-container">
              <PermissionsToggle />
            </div>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}
