import React, { JSX } from "react"
import { useTranslation } from "react-i18next"

import {
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import AddIcon from "@mui/icons-material/Add"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import { ReactComponent as SearchAIIcon } from "../../asset/img/ai_search_light_icon.svg"
import Tooltip from "../Tooltip"

import "./prompt-ai.sass"
import Input from "../Input"
import Pill from "../Pill"

interface AIPrompt {
  id: number
  value: string
}

export default function PromptAI(): JSX.Element {
  const [ expanded, setExpanded ] = React.useState(true)
  const [ aiSearch, setAISearch ] = React.useState("")
  const [ prompts, setPrompts ] = React.useState<AIPrompt[]>([])
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.AISearchFilter" })

  const removePrompt = (id: number) => {
    setPrompts(prompts.filter((prompt) => prompt.id !== id))
  }

  const addPrompt = () => {
    if (aiSearch.length === 0) return
    setPrompts([
      ...prompts,
      {
        id: prompts.length + 1,
        value: aiSearch,
      },
    ])
    setAISearch("")
  }

  return (
    <Stack className="cp_component-prompt-ai" borderRadius="2px">
      <Stack
        className="cp_component-prompt-ai-panel"
        padding={ 1 }
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" flex={ 1 } gap={ 1 }>
          <SearchAIIcon />
          <Typography fontWeight={ 600 } fontSize="0.9rem">
            { translate("AI Search") }
          </Typography>
        </Stack>
        <IconButton size="small" onClick={ () => setExpanded(!expanded) } className={ expanded ? "expanded" : "" }>
          <ExpandMoreIcon />
        </IconButton>
      </Stack>

      <Collapse in={ expanded }>
        <Stack padding={ 2 } gap={ 1 }>
          <Stack direction="row" alignItems="center" gap={ 0.5 }>
            <Typography fontSize="0.75rem" fontWeight={ 700 }>
              { translate("Influential AI Search") }
            </Typography>
            <Tooltip title={ (
              <Typography fontSize="0.75rem">
                { translate("Enter a word or phrase to find related Accounts and Content.") }
              </Typography>
            ) }
            >
              <InfoOutlinedIcon fontSize="inherit" className="cp_component-prompt-ai-icon-muted" color="inherit" />
            </Tooltip>
          </Stack>
          <Input
            id="cp_component-prompt-ai-input"
            value={ aiSearch }
            onChange={ (e) => setAISearch(e.target.value) }
            placeholder={ translate("Ask Influential AI") }
            InputProps={ {
              startAdornment: (
                <AutoAwesomeIcon htmlColor="#C5CACE" fontSize="small" />
              ),
              endAdornment: (
                <IconButton id="cp_component-prompt-ai-add" size="small" onClick={ addPrompt }>
                  <AddIcon htmlColor="#C5CACE" fontSize="small" />
                </IconButton>
              ),
            } }
          />
          <Stack marginTop={ 1 } flexWrap="wrap" direction="row" alignItems="center" gap={ 0.5 }>
            { prompts.map((prompt, i) => (
              <Pill
                id={ `cp_component-prompt-ai-prompt-${ i }` }
                key={ prompt.id }
                label={ prompt.value }
                onDelete={ () => removePrompt(prompt.id) }
              />
            )) }
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  )
}
