import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, CardMedia } from "@mui/material"
import {
  Attachment,
  InsertPhotoOutlined,
  CheckCircleOutlineOutlined,
} from "@mui/icons-material"
import * as GraphQL from "../../graphql"
import Avatar from "../Avatar"
import { formatTimestampToCustomDate, limitTextLength } from "../../util/miscHelper"

import "./communications-reading-panel-view.sass"

interface ConversationData {
  hasMessages: boolean,
  messages: GraphQL.GetConversationQuery["getConversation"]["conversationThread"]["timeline"],
  subject: GraphQL.GetConversationQuery["getConversation"]["subject"],
  socialAccount: GraphQL.GetConversationQuery["getConversation"]["socialAccount"] | null,
}

interface CommMessageProps {
  message: ConversationData["messages"][0],
  socialAccount: ConversationData["socialAccount"],
  indx: number,
  totalMessages: number,
}

export function CommMessage({
  message, socialAccount, indx, totalMessages,
}: CommMessageProps) {
  if (message.__typename !== "ConversationMessage") return null
  const { t: translate } = useTranslation([], { keyPrefix: "component.CommunicationsBody" })
  const [ showFullText, setShowFullText ] = React.useState(false)
  const fromName = message.outgoing ? message.from.alias : socialAccount?.userName
  const imageUrl = message.outgoing
    ? undefined
    : socialAccount?.profilePictureUrl || undefined
  const fromEmail = message.from.address
  const { created } = message
  const to = message.to.length ? message.to.map((toEmail) => toEmail.address).join(", ") : ""
  const cc = message.cc.length ? message.cc.map((ccEmail) => ccEmail.address).join(", ") : ""
  const bodyText = message.text
  const hasAttachments = message.attachments.length > 0

  const shouldShowFullText = () => {
    if (totalMessages === 1) return true
    if (totalMessages === 2 && indx === 1) return true
    return false
  }

  useEffect(() => {
    setShowFullText(shouldShowFullText())
  }, [ totalMessages, indx ])

  return (
    <div
      key={ message.id }
      className="cp_ct-thread single-message"
    >
      <div className="cp_ct-thread-message-content">
        <div
          className="cp_ct-thread-message-content-top"
          onClick={ () => { setShowFullText(!showFullText) } }
          onKeyDown={ (e) => {
            if (e.key === "Enter" || e.key === " ") {
              setShowFullText(!showFullText)
            }
          } }
          role="button"
          tabIndex={ 0 }
        >
          <Avatar size="sm" src={ imageUrl } className="cp_ct-thread-avatar">
            { fromName ? fromName.charAt(0) : "" }
          </Avatar>

          <p className="cp_ct-thread-handle-name">
            { fromName }
          </p>
          <p className="cp_ct-thread-message-address">{ fromEmail }</p>
          <p className="cp_ct-thread-date">{ formatTimestampToCustomDate(created) }</p>
        </div>
        <p className={ `cp_ct-thread-to ${ cc ? "has-cc" : "" }` }>{ `to ${ to }` }</p>
        { cc && <p className="cp_ct-thread-cc">{ `cc ${ cc }` }</p> }
        { showFullText
          ? <p className="cp_ct-thread-body">{ bodyText }</p>
          : <p className="cp_ct-thread-body shorten">{ limitTextLength(bodyText, 140) }</p>
        }
        { hasAttachments && (
        <>
          <div className="cp_ct-thread-content-top">
            <Attachment className="email-attachment-icon attachment" />
            <p>{ translate("attachments") }</p>
          </div>
          <div className="cp_ct-thread-attachments">
            { message.attachments.map((attachment) => (
              <Link
                href={ attachment.content.downloadUrl }
                download={ attachment.name }
                className="cp_ct-thread-attachment"
              >
                { (attachment.thumbnail && attachment.thumbnail.url)
                  ? (
                    <CardMedia
                      component="img"
                      height="40"
                      image={ attachment.thumbnail.url }
                      alt={ attachment.name }
                    />
                  )
                  : (
                    <div className="cp_ct-thread-attachment-icon-placeholder">
                      <InsertPhotoOutlined className="cp_ct-thread-attachment-icon-download" />
                    </div>
                  ) }
              </Link>
            )) }
          </div>
        </>
        ) }
      </div>
    </div>
  )
}

interface ConversationUploadsProps {
  message: ConversationData["messages"][0],
  socialAccount: ConversationData["socialAccount"],
}
export function ConversationUploads({
  message, socialAccount,
}: ConversationUploadsProps) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CommunicationsBody" })
  if (message.__typename !== "ConversationUpload" || !socialAccount) return null
  const fromName = socialAccount.userName
  const fromEmail = `(${ message.from.address })`
  const { created, uuid } = message
  const hasAttachments = message.attachments.length > 0
  const hasSnippet = message.snippets.length > 0
  const imageUrl = socialAccount.profilePictureUrl || undefined

  return (
    <div
      key={ uuid }
      className="cp_ct-thread single-message upload"
    >
      <div className="cp_ct-thread-message-content">
        <div className="cp_ct-thread-message-content-top">
          <Avatar size="sm" src={ imageUrl } className="cp_ct-thread-avatar">
            { fromName ? fromName.charAt(0) : "" }
          </Avatar>

          <p className="cp_ct-thread-handle-name">
            { fromName }
          </p>
          <p className="cp_ct-thread-message-address">{ fromEmail }</p>
          <p className="cp_ct-thread-date">{ formatTimestampToCustomDate(created) }</p>
        </div>
        <div className="cp_ct-thread-content">
          <h2 className="cp_ct-thread-content-heading">
            { `${ fromName } ${ translate("Uploaded Content via the Content Drop Zone") }` }
          </h2>
          <div className="cp_ct-thread-content-top">
            { hasAttachments && (
            <>
              <Attachment className="email-attachment-icon attachment" />
              <p>{ translate("attachments") }</p>
            </>
            ) }
            { hasSnippet && (
            <>
              <CheckCircleOutlineOutlined className="email-attachment-icon check" />
              <p>{ translate("Caption Submitted") }</p>
            </>
            ) }
          </div>
          { hasAttachments && (
            <div className="cp_ct-thread-attachments">
              { message.attachments.map((attachment) => (
                <Link
                  key={ attachment.id }
                  href={ attachment.content.downloadUrl }
                  download={ attachment.name }
                  className="cp_ct-thread-attachment"
                >
                  { (attachment.thumbnail && attachment.thumbnail.url)
                    ? (
                      <CardMedia
                        component="img"
                        height="40"
                        image={ attachment.thumbnail.url }
                        alt={ attachment.name }
                      />
                    )
                    : (
                      <div className="cp_ct-thread-attachment-icon-placeholder">
                        <InsertPhotoOutlined className="cp_ct-thread-attachment-icon-download" />
                      </div>
                    )
                  }
                </Link>
              )) }
            </div>
          ) }
          { hasSnippet && (
            <div className="cp_ct-thread-snippets">
              { message.snippets.map((snippet) => (
                <div key={ snippet.id } className="cp_ct-thread-snippet">
                  <p className="cp_ct-thread-snippet-text">{ snippet.text }</p>
                </div>
              )) }
            </div>
          ) }
        </div>
      </div>
    </div>
  )
}
