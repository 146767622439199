import React, {
  useState,
  JSX,
  useEffect,
} from "react"
import CloseIcon from "@mui/icons-material/Close"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"
import { SelectChangeEvent } from "@mui/material/Select"
import { useTranslation } from "react-i18next"
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
  IconButton,
} from "@mui/material"

import * as API from "../../util/apiClient"
import * as ComponentHelper from "../../util/componentHelper"
import * as GraphQL from "../../graphql"
import * as Misc from "../../util/miscHelper"
import Avatar from "../Avatar"
import AvatarUploader from "../AvatarUploader"
import Divider from "../Divider"
import Input from "../Input"
import NetworkButton from "../NetworkButton"
import Pill from "../Pill"
import Radio from "../Radio"
import Select from "../Select"
import TagsAutocomplete from "./TagsAutocomplete"
import TeamMembersAutocomplete, { AutocompleteLabels } from "./TeamMembersAutocomplete"
import { DEFAULT_MIN_AGE, DEFAULT_MAX_AGE } from "../../util/constant"
import { ListMode } from "."
import { Vertical, Scope } from "../../util/types"
import { uploadFile } from "../../util/miscHelper"
import { useDispatch, useSelector } from "../../state/hooks"
import {
  setNetwork,
  setName,
  setDescription,
  setSexes,
  setMinAge,
  setMaxAge,
  setSuggestionListMode,
  setSuggestionAccessCode,
  setIsPublic,
  setSubscribedUserIds,
  setAvatarId,
  setSuggestionListVerticalIds,
} from "../../state/listModalFormSlice"

import "./details.sass"

interface Props {
  mode: ListMode,
}

function DetailsTab({ mode }: Props) {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ListFormModal" })
  const { t } = useTranslation("common")

  const dispatch = useDispatch()
  const listModalFormState = useSelector((state) => state.listModalForm)
  const listManagerState = useSelector((state) => state.listFormManager)
  const { scopes } = useSelector(({ user: userSlice }) => userSlice)

  const [ minAgeOptions, setMinAgeOptions ] = useState<string[]>([ "" ])
  const [ maxAgeOptions, setMaxAgeOptions ] = useState<string[]>([ "" ])
  const [ tags, setTags ] = useState<Vertical[]>([])
  const [ avatarURL, setAvatarURL ] = useState<string>("")
  const [ requireCode, setRequiredCode ] = useState(GraphQL.SuggestionAccessCodeRestriction.RequiredToView)
  const [ selectedTeamMembers, setSelectedTeamMembers ] = useState<AutocompleteLabels[]>([])

  useEffect(() => {
    // Edit Tags mapping
    if (mode.isEditMode && API.isSuccess(listManagerState.originalList)) {
      const {
        verticals, subscribedUsers, suggestionAccessCode, avatar,
      } = listManagerState.originalList.payload.suggestionListById
      if (verticals && verticals.length) {
        const existingVerticals = verticals.map((vertical) => ({ id: vertical.id, name: vertical.name }))
        setTags(existingVerticals)
      }
      if (subscribedUsers && subscribedUsers.length) {
        const existingSubscribedUsers = subscribedUsers.map((user) => (
          {
            id: user.id,
            name: `${ user.contact.name } (${ user.username })`,
          }))
        setSelectedTeamMembers(existingSubscribedUsers)
      }
      if (suggestionAccessCode && suggestionAccessCode.accessCode.length) {
        const { suggestionAccessCodeRestriction } = suggestionAccessCode
        const codeRestriction = suggestionAccessCodeRestriction === GraphQL.SuggestionAccessCodeRestriction.RequiredToView
          ? GraphQL.SuggestionAccessCodeRestriction.RequiredToView
          : GraphQL.SuggestionAccessCodeRestriction.RequiredToEdit
        setRequiredCode(codeRestriction)
      }
      if (avatar && avatar.url.address.length) {
        setAvatarURL(avatar.url.address)
      }
    }
  }, [ listManagerState.originalList ])

  const handleRemoveTags = (tagId: string): void => {
    const selectedVerticals = [ ...listModalFormState.suggestionListVerticalIds ]
    const selectedTags = [ ...tags ]
    const filteredTagIds = selectedVerticals
      .filter((tag: string) => (tag !== tagId))

    const filteredTags = selectedTags
      .filter((tag: Vertical) => (tag.id !== tagId))

    dispatch(setSuggestionListVerticalIds(filteredTagIds))
    setTags(filteredTags)
  }

  function renderTagPills(): JSX.Element[] {
    return tags.map((tag) => (
      <Pill
        key={ tag.id }
        label={ tag.name }
        onDelete={ () => handleRemoveTags(tag.id) }
      />
    ))
  }

  const handleTagsChange = (selectedTags: Vertical[]) => {
    const tagIds: string[] = selectedTags.map(({ id }) => id)
    setTags(selectedTags)
    dispatch(setSuggestionListVerticalIds(tagIds))
  }

  const generateMinAgeOptions = (upToAge: number) => {
    const minOptions = Array.from(
      { length: upToAge - DEFAULT_MIN_AGE + 1 },
      (_, index) => (DEFAULT_MIN_AGE + index).toString(),
    )
    minOptions.unshift(translate("Min Age"))

    setMinAgeOptions(minOptions)
  }

  const generateMaxAgeOptions = (fromAge: number) => {
    const maxOptions = Array.from(
      { length: DEFAULT_MAX_AGE - fromAge + 1 },
      (_, index) => (fromAge + index).toString(),
    )
    maxOptions.unshift(translate("Max Age"))

    setMaxAgeOptions(maxOptions)
  }

  const handleMinAgeChange = (event: SelectChangeEvent<string>) => {
    dispatch(setMinAge(Number(event.target.value)))
  }

  const handleMaxAgeChange = (event: SelectChangeEvent<string>) => {
    dispatch(setMaxAge(Number(event.target.value)))
  }

  const handleSexesSelectChange = (gender: GraphQL.Sex) => {
    let updatedGenders = [ ...listModalFormState.sexes ]

    if (updatedGenders.includes(gender)) {
      updatedGenders = updatedGenders.filter((sex) => sex !== gender)
    } else {
      updatedGenders.push(gender)
    }

    dispatch(setSexes(updatedGenders))
  }

  const handleNetworkSelection = (network: GraphQL.Network) => {
    dispatch(setNetwork(network))
  }

  const handlePublicViewChange = () => {
    if (listModalFormState.isPublic) {
      dispatch(setSuggestionAccessCode(null))
    }
    dispatch(setIsPublic(!listModalFormState.isPublic))
  }

  // Generic function to check if radio option exist on enum
  function isValueInEnum(value: string, enumType: any): boolean {
    return Object.values(enumType).includes(value)
  }

  const handleSuggestionAccessCodeChange = (code: string) => {
    if (code.length) {
      dispatch(setSuggestionAccessCode({
        accessCode: code,
        suggestionAccessCodeRestriction: requireCode,
      }))
    } else {
      dispatch(setSuggestionAccessCode(null))
    }
  }

  const handleRemoveTeamMember = (tmember: AutocompleteLabels): void => {
    const filteredTeamMembers = selectedTeamMembers
      .filter((member: AutocompleteLabels) => (member.name !== tmember.name))

    const updatedTeamIds = filteredTeamMembers.map((member) => member.id)
    dispatch(setSubscribedUserIds(updatedTeamIds))
    setSelectedTeamMembers(filteredTeamMembers)
  }

  function renderTeamMemberPills(): JSX.Element[] {
    return selectedTeamMembers.map((member) => (
      <Pill
        key={ member.id }
        label={ member.name }
        onDelete={ () => handleRemoveTeamMember(member) }
      />
    ))
  }

  const handleSubscribedUsersUpdate = (teamMembers: AutocompleteLabels[]) => {
    const teamMemberIds = teamMembers.map((member) => member.id)
    dispatch(setSubscribedUserIds(teamMemberIds))
    setSelectedTeamMembers(teamMembers)
  }

  const handleClientAvatarUpdate = async (file: File) => {
    const fileStatus = await uploadFile(file)
    if (fileStatus.id) {
      dispatch(setAvatarId(`${ fileStatus.id }`))
      setAvatarURL(fileStatus.url)
    }
  }

  const handleDeleteAvatarId = () => {
    dispatch(setAvatarId(null))
    setAvatarURL("")
  }

  const renderAvatar = () => (avatarURL ? (
    <Avatar
      src={ avatarURL }
      className="cp_component_modal-create-list_logo-avatar"
      alt="Create List Avatar"
    />
  ) : (
    <AvatarUploader
      customClasses="cp_component_modal-create-list_logo"
      title={ translate("Client Logo") }
      infoText={ translate("Click to upload photo") }
      onAvatarSelect={ handleClientAvatarUpdate }
    />
  ))

  useEffect(() => {
    if (listModalFormState.suggestionAccessCode?.accessCode) {
      handleSuggestionAccessCodeChange(listModalFormState.suggestionAccessCode.accessCode)
    }
  }, [ requireCode ])

  // Min - Max age
  useEffect(() => {
    generateMinAgeOptions(listModalFormState.maxAge || DEFAULT_MIN_AGE)
    generateMaxAgeOptions(listModalFormState.minAge || DEFAULT_MAX_AGE)
  }, [])

  useEffect(() => {
    generateMaxAgeOptions(listModalFormState.minAge || DEFAULT_MIN_AGE)
  }, [ listModalFormState.minAge ])

  useEffect(() => {
    generateMinAgeOptions(listModalFormState.maxAge || DEFAULT_MAX_AGE)
  }, [ listModalFormState.maxAge ])

  return (
    <div className="cp_component_modal-create-list_tab-details">
      <div className="cp_component_modal-create-list_row">
        <div className="cp_component_modal-create-list_column">
          <h4 className="headline-small">{ translate("Basics") }</h4>
          <div className="cp_component_modal-create-list_avatar-upload">
            { renderAvatar() }
            { listModalFormState.avatarId && (
              <IconButton
                className="cp_avatar-removal"
                aria-label="delete"
                onClick={ handleDeleteAvatarId }
              >
                <CloseIcon />
              </IconButton>
            ) }
          </div>
          <div className="cp_component_modal-create-list_network">
            <h5>
              Network
              <span>*</span>
            </h5>
            <div className="cp_component_modal-create-list_network-buttons">
              { Misc
                .buildNetworkListByScope(scopes)
                .map((network) => (
                  <NetworkButton
                    id={ `cp_component_modal-create-list_network-button_${ network.toLowerCase() }` }
                    isActive={ listModalFormState.network === network }
                    key={ network }
                    network={ network }
                    onClick={ () => handleNetworkSelection(network) }
                  />
                )) }
            </div>
            <p>This cannot be edited later</p>
          </div>
          <div className="cp_component_modal-create-list-basic_fields">
            <Input
              value={ listModalFormState.name ?? "" }
              placeholder={ translate("Enter List Name") }
              onChange={ (e) => dispatch(setName(e.currentTarget.value)) }
              fullWidth={ true }
              label={ translate("List Name") }
            />
            <Input
              className="create-list-long-description"
              value={ listModalFormState.description ?? "" }
              placeholder={ translate("Enter List Description") }
              onChange={ (e) => dispatch(setDescription(e.currentTarget.value)) }
              fullWidth={ true }
              label={ translate("List Description") }
            />
            <div className="create-list-tags">
              <TagsAutocomplete
                setSelectedVerticals={ handleTagsChange }
                selectedVerticals={ tags }
                inputLabel={ translate("Tags") }
                notFoundLabel={ t("No results found") }
              />
              <small>
                { translate("Tags additional info") }
              </small>
              <div className="cp_component_modal-create-list_tag_pills">
                {
                renderTagPills()
              }
              </div>
            </div>
          </div>
          <Divider className="cp_component_modal-create-list-divider" />
          <div className="cp_component_modal-create-list_demographic-target">
            <h4 className="headline-small">{ translate("Demographic Targeting") }</h4>
            <p className="supporting-text">{ translate("Demographic disclaimer") }</p>
            <div className="cp_component_modal-create-list_demographic-wrapper">
              <div className="cp_component_modal-create-list_gender">
                { /* Gender section */ }
                <FormControl component="fieldset" fullWidth={ true }>
                  <FormLabel component="legend" className="section-label">
                    { t("Gender") }
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={ (
                        <Checkbox
                          checked={ listModalFormState.sexes.includes(GraphQL.Sex.Female) }
                          onChange={ () => handleSexesSelectChange(GraphQL.Sex.Female) }
                          name={ t("Female") }
                        />
                  ) }
                      label={ t("Female") }
                    />
                    <FormControlLabel
                      control={ (
                        <Checkbox
                          checked={ listModalFormState.sexes.includes(GraphQL.Sex.Male) }
                          onChange={ () => handleSexesSelectChange(GraphQL.Sex.Male) }
                          name={ t("Male") }
                        />
                  ) }
                      label={ t("Male") }
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div className="cp_component_modal-create-list_age">
                { /* Min & Max age section */ }
                <FormControl
                  className="age-range"
                  component="fieldset"
                  fullWidth={ true }
                >
                  <FormLabel component="legend" className="section-label">
                    { t("AGE RANGE") }
                  </FormLabel>
                  <div className="cp_component_modal-create-list_min-age">
                    <FormLabel component="legend">
                      { t("From") }
                    </FormLabel>
                    <Select
                      value={ listModalFormState.minAge
                        ? `${ listModalFormState.minAge }`
                        : `${ minAgeOptions[0] }`
                      }
                      onChange={ handleMinAgeChange }
                      displayEmpty={ true }
                      id="minAge"
                      name="minAge"
                      label=""
                      labelId="minAge"
                      menuItems={
                        ComponentHelper.selectionOptions(minAgeOptions, true)
                      }
                    />
                  </div>
                  <div className="cp_component_modal-create-list_max-age">
                    <FormLabel component="legend">
                      { t("To") }
                    </FormLabel>
                    <Select
                      value={ listModalFormState.maxAge
                        ? `${ listModalFormState.maxAge }`
                        : `${ maxAgeOptions[0] }`
                      }
                      onChange={ handleMaxAgeChange }
                      displayEmpty={ true }
                      id="maxAge"
                      name="maxAge"
                      label=""
                      labelId="maxAge"
                      menuItems={
                        ComponentHelper.selectionOptions(maxAgeOptions, true)
                      }
                    />
                  </div>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div className="cp_component_modal-create-list_column">
          <div className="cp_component_modal-create-list_list-settings">
            <h4 className="headline-small">{ translate("List Settings") }</h4>
            { scopes.includes(Scope.SUGGESTION_LIST_MODE) && (
            <div className="cp_component_modal-create-list-settings_mode">
              <FormControl>
                <FormLabel className="cp_component_modal-create-list-settings_mode-label">
                  { translate("Mode") }
                </FormLabel>
                <RadioGroup
                  onChange={ (_, selectedMode) => {
                    if (isValueInEnum(selectedMode, GraphQL.SuggestionListMode)) {
                      dispatch(setSuggestionListMode(selectedMode as GraphQL.SuggestionListMode))
                    }
                  } }
                  className="cp_component_modal-create-list-settings_mode-radios"
                  value={ listModalFormState.suggestionListMode }
                >
                  <FormControlLabel
                    value={ GraphQL.SuggestionListMode.Standard }
                    control={ <Radio /> }
                    label={ translate("Standard Mode") }
                    checked={ listModalFormState.suggestionListMode === GraphQL.SuggestionListMode.Standard }
                  />
                  <FormControlLabel
                    value={ GraphQL.SuggestionListMode.Campaign }
                    control={ <Radio /> }
                    label={ translate("Campaign Mode") }
                    checked={ listModalFormState.suggestionListMode === GraphQL.SuggestionListMode.Campaign }
                  />
                </RadioGroup>
              </FormControl>
              <div className="cp_component_modal-create-list-settings_mode-info">
                { translate("Campaign mode approvals") }
              </div>
            </div>
            ) }
            <div className="cp_component_modal-create-list-settings_access">
              { /* Access section */ }
              <FormControl component="fieldset" fullWidth={ true }>
                <FormLabel component="legend" className="cp_component_modal-create-list-settings_access-label">
                  { translate("ACCESS") }
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={ (
                      <Checkbox
                        checked={ listModalFormState.isPublic }
                        onChange={ handlePublicViewChange }
                        name={ translate("Publicly Viewable") }
                      />
                  ) }
                    label={ translate("Publicly Viewable") }
                  />
                </FormGroup>
                { listModalFormState.isPublic
                  && (
                  <Input
                    className="cp_component_modal-create-list-settings_access-code"
                    value={ listModalFormState.suggestionAccessCode?.accessCode ?? "" }
                    placeholder={ translate("Enter Access Code") }
                    onChange={ (e) => handleSuggestionAccessCodeChange(e.currentTarget.value) }
                    fullWidth={ true }
                    label={ translate("Access Code") }
                  />
                  )
                }
              </FormControl>
              { listModalFormState.suggestionAccessCode?.accessCode && (
              <FormControl>
                <RadioGroup
                  onChange={ (_, selectedOption) => {
                    setRequiredCode(selectedOption as GraphQL.SuggestionAccessCodeRestriction)
                  } }
                  className="cp_component_modal-create-list-settings_access-radios"
                  value={ requireCode }
                >
                  <FormControlLabel
                    value={ GraphQL.SuggestionAccessCodeRestriction.RequiredToView }
                    control={ <Radio /> }
                    label={ translate("Require code to view list") }
                    checked={
                      requireCode === GraphQL.SuggestionAccessCodeRestriction.RequiredToView
                    }
                  />
                  { listModalFormState.suggestionListMode === GraphQL.SuggestionListMode.Campaign && (
                  <FormControlLabel
                    value={ GraphQL.SuggestionAccessCodeRestriction.RequiredToEdit }
                    control={ <Radio /> }
                    label={ translate("Require code to take action only") }
                    checked={
                      requireCode === GraphQL.SuggestionAccessCodeRestriction.RequiredToEdit
                    }
                  />
                  ) }
                </RadioGroup>
              </FormControl>
              ) }
            </div>
          </div>
          { listModalFormState.suggestionListMode !== GraphQL.SuggestionListMode.Standard && (
          <div className="cp_component_modal-create-list_list-notifications">
            <h4 className="headline-small">
              <NotificationsNoneIcon className="cp_icon_notification-bell" />
              { translate("List Notifications") }
            </h4>
            { /* Notify team members section */ }
            <FormControl
              className="cp_component_modal-create-list_list-notifications_team-members"
              component="fieldset"
              fullWidth={ true }
            >
              <FormLabel component="legend">
                { translate("Notify Team Members") }
                <span>
                  { ` ${ translate("(Internal)") }` }
                </span>
              </FormLabel>
              <TeamMembersAutocomplete
                setSelectedTeamMembers={ handleSubscribedUsersUpdate }
                selectedTeamMembers={ selectedTeamMembers }
                notFoundLabel={ t("No results found") }
              />
              <p className="cp_component_modal-create-list_list-notifications_info">
                { translate("Notification addition info") }
              </p>
              <div className="cp_component_modal-create-list_list-notifications_pills">
                {
                  renderTeamMemberPills()
                }
              </div>
            </FormControl>
          </div>
          ) }
        </div>
      </div>
    </div>
  )
}

export default DetailsTab
