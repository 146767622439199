import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { v4 as uuidv4 } from "uuid"
import "./style.sass"
import {
  Step,
  StepLabel,
  Stepper,
} from "@mui/material"
import Modal from "../Modal"
import { useDispatch, useSelector } from "../../state/hooks"
import LoadingIndicator from "../LoadingIndicator"
import {
  closeModalCreateDeliverable,
  pushStepCompleted,
  setActiveStep,
} from "../../state/ModalCreateDeliverable"
import NameDeliverableInputStep from "./NameDeliverableInputStep"
import Divider from "../Divider"
import SelectCaptionInputStep from "./SelectCaptionInputStep"

export default function ModalCreateDeliverable() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCreateDeliverable" })
  const { t: tCommon } = useTranslation([], { keyPrefix: "common" })

  const {
    open,
    activeStep,
    deliverableTitle,
    stepsCompleted,
    caption,
  } = useSelector(({ modalCreateDeliverable }) => modalCreateDeliverable)
  const [ submitLoading, setSubmitLoading ] = useState(false)
  const dispatch = useDispatch()

  const steps = useMemo(() => [
    { label: translate("Name Deliverable"), key: uuidv4() },
    { label: translate("Select Caption"), key: uuidv4() },
    { label: translate("Select Media"), key: uuidv4() },
    { label: translate("Review Deliverable"), key: uuidv4() },
  ], [ translate ])

  const handleClose = () => {
    dispatch(closeModalCreateDeliverable())
  }

  const handleSubmit = async () => {
    if (activeStep === 4) {
      setSubmitLoading(true)

      // Submit logic

      setSubmitLoading(false)
      handleClose()
    } else {
      dispatch(pushStepCompleted(activeStep))
      dispatch(setActiveStep(activeStep + 1))
    }
  }

  const getPrimaryLabel = () => {
    if (submitLoading) return <LoadingIndicator />
    if (activeStep === 4) return translate("Create Deliverable")
    return translate("Next")
  }

  const getStepContent = () => {
    switch (activeStep) {
      case 1:
        return <NameDeliverableInputStep />
      case 2:
        return <SelectCaptionInputStep />
      default:
        return null
    }
  }

  const getPrimaryButtonDisabledState = () => {
    if (submitLoading) return false
    switch (activeStep) {
      case 1:
        return deliverableTitle.length === 0
      case 2:
        return caption.length === 0

        // TODO: Fill in rest of step conditional states

      default:
        return false
    }
  }

  return (
    <Modal
      className="cp_component_modal-create-deliverable"
      title={ translate("Create Deliverable") }
      primaryLabel={ getPrimaryLabel() }
      secondaryLabel={ tCommon("Cancel") }
      primaryAction={ handleSubmit }
      secondaryAction={ handleClose }
      closeAction={ handleClose }
      open={ open }
      disabled={ getPrimaryButtonDisabledState() }
      secondaryDisabled={ submitLoading }
      maxWidth="lg"
    >
      <Stepper
        nonLinear={ true }
        alternativeLabel={ true }
        // Stepper component 0 indexes step numbers
        activeStep={ activeStep - 1 }
      >
        { steps.map((step, idx) => {
          let className = ""
          if (stepsCompleted.includes(idx + 1)) className = "step-completed"
          else if (activeStep === idx + 1) className = "step-active"
          return (
            <Step className={ className } key={ step.key }>
              <StepLabel>{ step.label }</StepLabel>
            </Step>
          )
        }) }
      </Stepper>
      <Divider />
      <div className="content-container">
        { getStepContent() }
      </div>
    </Modal>
  )
}
