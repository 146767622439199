import React, { JSX } from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../util/apiClient"
import Button from "../../component/Button"
import Error from "../Error"
import PageShell from "../../component/PageShell"
import SearchFilter from "../../component/AISearchFilter"
import SearchInitiator from "./SearchInitiator"
import SearchResults from "../../component/SearchResults"
import ToastController from "../../controller/Toast"
import { Scope } from "../../util/types"
import { fetchSearchResults } from "../../state/searchSlice"
import { useDispatch, useSelector } from "../../state/hooks"

import "./style.sass"

export default function Search(): JSX.Element {
  const { t: translate } = useTranslation([], { keyPrefix: "page.Search" })
  const dispatch = useDispatch()
  const { user, scopes } = useSelector(({ user: userSlice }) => userSlice)

  // User state is loading... show minimal page frame without results...
  if (!API.isSuccess(user)) {
    return (
      <div className="cp_page_search">
        <ToastController />
        <PageShell
          title={ translate("Search") }
          showTopNav={ false }
        />
      </div>
    )
  }

  // User is logged in but doesn't have `feature_search` scope
  if (API.isSuccess(user) && !scopes.includes(Scope.FEATURE_SEARCH)) {
    return <Error statusCode={ 404 } />
  }

  return (
    <div className="cp_page_search">
      <ToastController />
      <PageShell title={ translate("Search") } />
      <div className="cp_page_search-container">
        <section className="cp_page_search-form">
          <SearchFilter />
          <aside className="cp_page_search-button">
            <Button
              isEnabled={ true }
              isPrimary={ true }
              label={ translate("Search") }
              onClick={ () => dispatch(fetchSearchResults(true)) }
            />
          </aside>
        </section>
        <section className="cp_page_search-results">
          <SearchResults
            mountContext="search"
            contentContextMenu={ {
              showAddPostButton: false,
              showViewMatchingPostsButton: true,
              showViewProfileButton: true,
            } }
          />

          { /* User data is loaded... initiate search! */ }
          <SearchInitiator />
        </section>
      </div>
    </div>
  )
}
