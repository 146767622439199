import React, { JSX } from "react"

import { UIMatch, useMatches } from "react-router-dom"
import { useTranslation } from "react-i18next"
import * as Type from "../util/types"
import { useSelector } from "../state/hooks"
import PageShell from "../component/PageShell"
import LoadingPage from "../page/Loading"

import "./scopes-guard.sass"

interface HandleProps {
  scopes?: Type.Scope[]
}

export default function ScopesGuard(props: {
  children: React.ReactNode;
}): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ScopesGuard" })
  const { scopes, user } = useSelector(({ user: userSlice }) => userSlice)
  const matches = useMatches() as UIMatch<unknown, HandleProps>[]

  const scopedMatches = matches
    .filter((match) => Boolean(match.handle?.scopes))

  const requiredScopes: Type.Scope[] = []

  scopedMatches.forEach((match) => {
    if (!match.handle.scopes) return
    requiredScopes.push(...match.handle.scopes)
  })

  const missingScopes = requiredScopes.filter((requiredScope) => !scopes.includes(requiredScope))

  if (user === "loading" || user === "init") {
    return <LoadingPage />
  }

  if (missingScopes.length > 0) {
    return (
      <div className="cp_guard_scopes">
        <PageShell title={ translate("Unauthorized Page") } />
        <div className="cp_guard_scopes-container">
          <h3>{ translate("You are not authorized to view this page.") }</h3>
          <p>
            <strong>
              { translate("You are missing the following scopes.") }
            </strong>
          </p>
          { missingScopes.map((missingScope, i) => (
            <p>
              { i + 1 }
              .
              { " " }
              { missingScope }
            </p>
          )) }
        </div>
      </div>
    )
  }

  return <>{ props.children }</>
}
