import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./group-accounts-body.sass"
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridTreeNode,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"
import {
  EditOutlined,
  Circle,
  DeleteOutlineOutlined,
} from "@mui/icons-material"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import TuneIcon from "@mui/icons-material/Tune"
import LabelImportantIcon from "@mui/icons-material/LabelImportant"
import { useParams } from "react-router-dom"
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import SearchBar from "../SearchBar"
import { useSelector, useDispatch } from "../../state/hooks"
import IconButton from "../IconButton"
import Dropdown from "../Dropdown"
import FilterMenu, { FilterMenuValue } from "../FilterMenu"
import Button from "../Button"
import { RootState } from "../../state/store"
import {
  getCommunicationGroup,
  getGroupAccounts,
  setGroupAccountReloadStatus,
  updateCommunicationGroupLabels,
} from "../../state/communicationsPage"
import * as API from "../../util/apiClient"
import { getColumnHeaders, getRowData } from "./GroupAccountsTableContent"
import {
  setSocialAccountId,
  setContactInfoModalOpen,
  setSocialAvatarInfo,
  resetContactInfoModal,
  setContactInfoModalData,
  ContactModalProps,
} from "../../state/groupAccountsContactInfoModal"
import Input from "../Input"
import { Scope } from "../../util/types"

/**
 * Type for the parameter, when clicking on a cell in the table
 */
type TableDataGridParams = GridCellParams<any, unknown, unknown, GridTreeNode>

/**
 * Type to represent a label pulled from database
 */
type CommsGroupLabel = {
  __typename?: "CommunicationGroupLabel"
  id: string
  label: string
}

/**
 * Component to render the content for group accounts for a specific campaign and/or list of accounts
 * @returns Renders the content of the group accounts
 */
export default function GroupAccountsBody() {
  const defaultLabels: string[] = [ "contracting", "opted-in", "garnering content" ]
  const { t: translate } = useTranslation([], { keyPrefix: "component.GroupAccountsBody" })
  const dispatch = useDispatch()
  const { commGroupID } = useParams()
  const { scopes } = useSelector(({ user }) => user)
  const {
    communicationGroupAccounts,
    relaodGroupAccounts: reload,
    communicationGroup: commsGroup,
  } = useSelector((root: RootState) => root.communicationsPage)
  const tableHeaders: GridColDef[] = getColumnHeaders(translate)

  // Initialize local state
  const [ searchInputValue, setSearchInputValue ] = useState("")
  const [ filterValue, setFilterValue ] = useState({} as FilterMenuValue)
  const [ editedLabelValue, setEditedLableValue ] = useState<CommsGroupLabel>({ label: "", id: "" })
  const rows: (GridValidRowModel | null)[] = (API.isSuccess(communicationGroupAccounts))
    ? communicationGroupAccounts.payload.searchCommunicationGroupNetworkAccount.rows.map((row) => getRowData(row)) : []
  const [ labels, setLabels ] = useState<CommsGroupLabel[]>(
    (API.isSuccess(commsGroup))
      ? commsGroup.payload.communicationGroup.labels.filter((lbl) => !defaultLabels.includes(lbl.label)) : [],
  )

  /**
   * Called initially to ensure that the communication group information is available in state
   */
  useEffect(() => {
    if (commsGroup === "init" && commGroupID) {
      dispatch(getCommunicationGroup({
        id: commGroupID,
      }))
    }
  }, [])

  /**
   * Sets the labels when the communication group state has changed
   */
  useEffect(() => {
    if (API.isSuccess(commsGroup)) {
      setLabels(commsGroup.payload.communicationGroup.labels.filter((lbl) => !defaultLabels.includes(lbl.label)))
    }
  }, [ commsGroup ])

  /**
   * Function to load the group accounts for this particular communication group
   */
  useEffect(() => {
    // Check to see if reloading the content after updating contact information
    if (reload) {
      dispatch(setGroupAccountReloadStatus(false))
      dispatch(getGroupAccounts({
        startsWith: searchInputValue,
        communicationGroupId: `${ commGroupID }`,
      }))
    }
  }, [ searchInputValue, filterValue, reload ])

  /**
   * Update the state value for search input, which should trigger a reload of the group accounts
   * @param event The element that contains the information on the search bar
   */
  const onSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Placeholder
    setSearchInputValue(event.target.value)
    dispatch(setGroupAccountReloadStatus(true))
  }

  const onEditClick = () => {
    // Placeholder
  }

  const onAddAccountClick = () => {
    // Placeholder
  }

  /**
   * Handles any clicks on cells in the table
   * @param param0 The cell and row that was clicked on in the table
   */
  const cellClickAction = ({ field, row }: TableDataGridParams) => {
    // Check to see if the contact info column was clicked on
    if (field === "contactInfo") {
      // Reset the form
      dispatch(resetContactInfoModal())

      // Set account id
      dispatch(setSocialAccountId(row.account.id))

      // Set the social avatar information
      dispatch(setSocialAvatarInfo({
        ...row.account,
      }))

      // Set modal data
      const modalData: ContactModalProps = {
        firstName: row.contactInfo.firstName,
        lastName: row.contactInfo.lastName,
        primaryEmail: row.contactInfo.email,
        websiteUrl: row.account.websiteUrl,
        emails: row.account.emails,
        emailsFromTeam: row.account.emailsFromTeam,
      }

      // Set required fields
      dispatch(setContactInfoModalData(modalData))

      // Display the modal
      dispatch(setContactInfoModalOpen(true))
    }
  }

  const applyLabel = (label: string) => {
    // Placeholder
    label.toString()
  }

  /**
   * Deletes a label from the communication group
   * @param label The label being deleted
   */
  const deleteLabel = (label: CommsGroupLabel) => {
    // Remove from local list of labels
    const labelsWithoutSelected = labels.filter((lbl) => lbl.id !== label.id)

    // Make sure we have access to comms group and a group id exists
    const lbls: string[] = [ ...defaultLabels, ...labelsWithoutSelected.map((lbl) => lbl.label) ]
    if (API.isSuccess(commsGroup) && commGroupID) {
      // Update database by removing the deleted label
      dispatch(updateCommunicationGroupLabels({
        communicationGroupId: commGroupID,
        name: commsGroup.payload.communicationGroup.name,
        labels: lbls,
        suggestionListIds: commsGroup.payload.communicationGroup.suggestionLists.map((sl) => sl.id),
        campaignId: commsGroup.payload.communicationGroup.campaign?.id,
        subscribedUserIds: commsGroup.payload.communicationGroup.subscribedUsers.map((susr) => susr.id),
      }))
    }

    // Set the new set of labels
    setLabels(labelsWithoutSelected)
  }

  /**
   * Updates the label name in the database
   * @returns void
   */
  const approveUpdateLabel = () => {
    // Don't need to do anything if no text exists
    if (editedLabelValue.label === "") return

    // Create new list of labels, with updated label value
    const lbls: CommsGroupLabel[] = []
    labels.forEach((lbl) => {
      if (lbl.id !== editedLabelValue.id) lbls.push(lbl)
    })
    lbls.push(editedLabelValue)

    // Make sure we have access to comms group and a group id exists
    const lblids: string[] = [ ...defaultLabels, ...lbls.map((lbl) => lbl.label) ]
    if (API.isSuccess(commsGroup) && commGroupID) {
      // Update database with new label value
      dispatch(updateCommunicationGroupLabels({
        communicationGroupId: commGroupID,
        name: commsGroup.payload.communicationGroup.name,
        labels: lblids,
        suggestionListIds: commsGroup.payload.communicationGroup.suggestionLists.map((sl) => sl.id),
        campaignId: commsGroup.payload.communicationGroup.campaign?.id,
        subscribedUserIds: commsGroup.payload.communicationGroup.subscribedUsers.map((susr) => susr.id),
      }))
    }

    // Update the screen with the new labels
    setLabels(lbls)

    // Clear the label
    setEditedLableValue({
      label: "",
      id: "",
    })
  }

  /**
   * Cancels the update to a label
   */
  const cancelUpdateLabel = () => {
    // Clear the label
    setEditedLableValue({
      label: "",
      id: "",
    })
  }

  /**
   * Updates the local state with the new label value
   * @param value The new updated value for the label
   */
  function editLabelValueChanged(value: string): void {
    // Update label value
    setEditedLableValue({
      __typename: editedLabelValue?.__typename,
      label: value,
      id: (editedLabelValue.id) ? editedLabelValue.id : "",
    })
  }

  // Return the JSX elements for this page
  return (
    <div className="cp_component_communications-group-accounts-container">
      <div className="header-section">
        <SearchBar className="search-bar" onChange={ (event) => onSearchInput(event) } />
        <div className="details-container">
          <Dropdown
            buttonType="custom"
            customButtonChildren={ <TuneIcon /> }
          >
            <FilterMenu
              filterOptions={ [] }
              filterValue={ filterValue }
              setFilterValue={ (value) => setFilterValue(value) }
            />
          </Dropdown>
          <IconButton onClick={ () => setFilterValue({ } as FilterMenuValue) }>
            <TuneIcon />
          </IconButton>
          { scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && (
            <Dropdown
              id="cp_label-dropdown"
              buttonType="custom"
              customButtonChildren={ <LabelImportantIcon className="important-label-button" /> }
            >
              <List disablePadding={ true }>
                <ListItem disablePadding={ true } disableGutters={ true }>
                  <ListItemButton
                    id="label-contracting"
                    onClick={ () => applyLabel("Contracting") }
                  >
                    <ListItemIcon>
                      <Circle className="details-container_label-style1" />
                    </ListItemIcon>
                    <ListItemText>
                      { translate("Contracting") }
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding={ true } disableGutters={ true }>
                  <ListItemButton
                    id="label-contracting"
                    onClick={ () => applyLabel("Opted-In") }
                  >
                    <ListItemIcon>
                      <Circle className="details-container_label-style2" />
                    </ListItemIcon>
                    <ListItemText>
                      { translate("Opted-In") }
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding={ true } disableGutters={ true }>
                  <ListItemButton
                    id="label-contracting"
                    onClick={ () => applyLabel("Garnering Content") }
                  >
                    <ListItemIcon>
                      <Circle className="details-container_label-style3" />
                    </ListItemIcon>
                    <ListItemText>
                      { translate("Garnering Content") }
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
                { labels.map.length > 0 && (<Divider />) }
                { labels.map((label, index) => (
                  <ListItem
                    className="details-container_personalized-label-list-item"
                    disablePadding={ true }
                    disableGutters={ true }
                  >
                    <ListItemButton
                      id={ `label-${ label.id }` }
                      onClick={ () => applyLabel(label.label) }
                    >
                      <ListItemIcon>
                        <Circle className={ `details-container_label-style${ (index % 5) }` } />
                      </ListItemIcon>
                      <ListItemText>
                        { label.label }
                      </ListItemText>
                    </ListItemButton>
                    <div id={ `modification-btn-group-${ label.id }` } className="modification-label-buttons">
                      <EditOutlined className="edit-label-icon-button" onClick={ () => { setEditedLableValue(label) } } />
                      <DeleteOutlineOutlined className="delete-label-icon-button" onClick={ () => { deleteLabel(label) } } />
                    </div>
                  </ListItem>
                )) }
                { labels.map.length > 0 && (
                  <div className="details-container_edit-label-container">
                    <Input
                      className="details-container_edit-label-container_input-field"
                      id="edit-label-input"
                      placeholder="a cool category"
                      size="small"
                      value={ editedLabelValue?.label }
                      onChange={ (e) => editLabelValueChanged(e.currentTarget.value) }
                    />
                    <CheckIcon className="update-label-check-button" onClick={ () => { approveUpdateLabel() } } />
                    <CloseIcon className="cancel-edit-label-button" onClick={ () => { cancelUpdateLabel() } } />
                  </div>
                ) }
              </List>
            </Dropdown>
          ) }
          <IconButton onClick={ onEditClick }>
            <EditOutlined />
          </IconButton>
          <Button
            className="add-account-button"
            label={ translate("+ ADD ACCOUNT") }
            onClick={ onAddAccountClick }
          />
        </div>
      </div>
      <div className="communications-body">
        <DataGridPro
          checkboxSelection={ true }
          columns={ tableHeaders }
          disableRowSelectionOnClick={ true }
          hideFooter={ true }
          rowHeight={ 80 }
          rows={ rows }
          loading={ (communicationGroupAccounts === "init" || communicationGroupAccounts === "loading") }
          pinnedColumns={ {
            left: [ "__check__", "account" ],
            right: [ "groupsContextMenu" ],
          } }
          onCellClick={ cellClickAction }
        />
      </div>
    </div>
  )
}
