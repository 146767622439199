import React from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import * as API from "../../../../util/apiClient"
import { useSelector } from "../../../../state/hooks"
import CampaignNativePostsReport from "./CampaignNativePostsReport"
import Trends from "./Trends"
import CampaignNativeInstagramStories from "./CampaignNativeInstagramStories"
import CampaignPaidMedia from "./CampaignPaidMedia"
import { PlatformInsights } from "./CampaignReporting"

import "./campaign-reporting.sass"
import { getDownloadReportCsvUrl } from "../../../../util/miscHelper"
import Button from "../../../Button"

export default function PublicCampaignReporting() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.PublicCampaignReporting" })
  const { report, metricDefinitions } = useSelector(({ publicCampaignReport }) => publicCampaignReport)

  const reportData = (API.isSuccess(report) && report.payload)
    ? report.payload.publicCampaignReport
    : undefined
  const reportMetricDefinitions = (API.isSuccess(metricDefinitions) && metricDefinitions.payload)
    ? metricDefinitions.payload.getCampaignReportMetricDefinitions
    : undefined
  const { reportCode } = useParams()

  const onExternalDownloadClick = () => {
    if (reportCode) {
      window.open(getDownloadReportCsvUrl({
        reportCode,
        filterLevel: 1,
      }), "_blank")
    }
  }

  return (
    <div className="cp_campaign_reporting_tab report public">
      <div className="cp_campaign_reporting_tab-actions">
        <h4>{ reportData?.name }</h4>
        <Button
          onClick={ onExternalDownloadClick }
          isPrimary={ false }
          label={ translate("Download CSV") }
        />
      </div>
      <div className="cp_card-metrics_sections">
        { (reportData && reportData.platformInsights.length !== 0) && (
        <PlatformInsights
          platformInsights={ reportData.platformInsights }
          reportMetricDefinitions={ reportMetricDefinitions ?? [] }
        />
        ) }
        { (reportData && reportData.deliverables.length !== 0) && (
          <>
            <CampaignNativePostsReport deliverables={ reportData.deliverables } toggles={ reportData.toggleSettings } />
            <CampaignNativeInstagramStories deliverables={ reportData.deliverables } toggles={ reportData.toggleSettings } />
            <CampaignPaidMedia deliverables={ reportData.deliverables } toggles={ reportData.toggleSettings } />
            <Trends
              influencerInsights={ reportData.influencerInsights }
              deliverableInsights={ reportData.deliverableInsights }
              objectiveInsights={ reportData.objectiveInsights }
              audienceInsights={ reportData.audienceInsights }
              creativeThemeInsights={ reportData.creativeThemeInsights }
              template={ reportData.template }
              toggles={ reportData.toggleSettings }
            />
          </>
        ) }
      </div>
    </div>
  )
}
