import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import AttachmentIcon from "@mui/icons-material/Attachment"
import { useSelector, useDispatch } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import Checkbox from "../Checkbox"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import ErrorHandler from "../ErrorHandler"
import InfiniteScroll from "../InfiniteScroll"
import {
  fetchEmailsList,
  resetConversations,
  resetPage,
  setPage,
  fetchConversationThread,
  resetCommMessages,
} from "../../state/commEmailListView"
import { formatTimestampToCustomDate, limitTextLength } from "../../util/miscHelper"
import { DEFAULT_CONVERSATION_QUERY } from "../../util/constant"

import "./communications-email-list-view.sass"

interface Conversation {
  id: string,
  handleName: string,
  date: string,
  subject: string,
  body: string,
  hasAttachment: boolean,
}

export default function CommunicationsEmailListView() {
  const { commGroupID } = useParams()
  const dispatch = useDispatch()
  const {
    conversations, page, communicationMessages,
  } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)
  const [ processedConversations, setProcessedConversations ] = React.useState<Conversation[]>([])
  const [ activeCommunication, setActiveCommunication ] = React.useState<string | null>(null)

  const fetchConversations = () => {
    if (commGroupID) {
      const conversationInfo: GraphQL.SearchConversationsQueryVariables = {
        communicationGroupId: commGroupID,
        startsWith: DEFAULT_CONVERSATION_QUERY.STARTS_WITH,
        page,
        limit: DEFAULT_CONVERSATION_QUERY.LIMIT,
        filter: DEFAULT_CONVERSATION_QUERY.FILTER,
        batchId: DEFAULT_CONVERSATION_QUERY.BATCH_ID,
      }
      dispatch(fetchEmailsList(conversationInfo))
      dispatch(setPage())
    }
  }

  useEffect(() => {
    if (conversations === "init") {
      fetchConversations()
    }

    return () => {
      dispatch(resetConversations())
      dispatch(resetCommMessages())
      dispatch(resetPage())
    }
  }, [])

  const processConversations = (rows: GraphQL.ConversationRowFragment[]) => {
    const currConversations = [ ...processedConversations ]
    const nextConversations = rows.map((conversation) => ({
      id: conversation.id,
      handleName: conversation.socialAccount?.userName,
      date: formatTimestampToCustomDate(conversation.conversationThread?.lastMessage?.created),
      subject: conversation.subject,
      body: limitTextLength(conversation.conversationThread?.lastMessage?.text, 140),
      hasAttachment: conversation.conversationThread?.attachments?.length > 0,
    }))
    setProcessedConversations([ ...currConversations, ...nextConversations ])
  }

  const handleActiveCommunication = (id: string) => {
    // if converstation is loading, return
    if (communicationMessages === "loading") return
    // Toggle the active communication
    if (activeCommunication === id) {
      setActiveCommunication(null)
      dispatch(resetCommMessages())
    } else {
      setActiveCommunication(id)
      dispatch(fetchConversationThread(id))
    }
  }

  useEffect(() => {
    if (conversations === "init" || conversations === "loading") return

    if (API.isSuccess(conversations)) {
      const onlyConversations = conversations.payload.searchConversation.rows
        .filter((conversation) => conversation.__typename === "Conversation")

      processConversations(onlyConversations as GraphQL.ConversationRowFragment[])
    }
  }, [ conversations ])

  if (conversations === "init" || conversations === "loading") {
    return (
      <div className="communications-email-list-view loading">
        <LoadingIndicatorCard />
      </div>
    )
  }

  if (conversations.status === "error") {
    return (
      <div className="communications-email-list-view error">
        <ErrorHandler />
      </div>
    )
  }

  return (
    <div className="communications-email-list-view">
      <section className="email-list-heading"><Checkbox /></section>
      <InfiniteScroll
        dataLength={ processedConversations.length }
        next={ fetchConversations }
        hasMore={ conversations.payload.searchConversation.totalCount > processedConversations.length }
      >
        <section className="email-list-emails">
          { processedConversations.map((conversation) => (
            <div
              key={ conversation.id }
              onClick={ () => { handleActiveCommunication(conversation.id) } }
              id={ `cp_component-email-list-email${ activeCommunication === conversation.id ? "-selected" : "" }` }
              className={ `email-list-email ${ activeCommunication === conversation.id ? "selected" : "" }` }
              onKeyDown={ (e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleActiveCommunication(conversation.id)
                }
              } }
              role="button"
              tabIndex={ 0 }
            >
              <div className="email-list-email-selector">
                <Checkbox checked={ conversation.id === activeCommunication } />
              </div>
              <div className="email-content">
                <div className="email-content-top">
                  <p className="handle-name">{ conversation.handleName }</p>
                  <div className="email-content-top-right">
                    { conversation.hasAttachment && (
                      <div className="email-has-attachment"><AttachmentIcon className="email-attachment-icon" /></div>
                    ) }
                    <p className="date">{ conversation.date }</p>
                  </div>
                </div>
                <p className="subject">{ conversation.subject }</p>
                <p className="body">{ conversation.body }</p>
              </div>
            </div>
          )) }
        </section>
      </InfiniteScroll>
    </div>
  )
}
