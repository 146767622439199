import { configureStore } from "@reduxjs/toolkit"
import StorageUtil from "../util/localStorage"

import breadcrumbSlice from "./breadcrumbSlice"
import campaign from "./campaignSlice"
import campaignDeliverableContentModal from "./campaignDeliverableContentModalSlice"
import campaignDeliverableMetricModal from "./campaignDeliverableMetricModalSlice"
import campaignDeliverableModal from "./campaignDeliverableModalSlice"
import campaignDeliverableAssignReviewerModal from "./campaignDeliverableAssignReviewerModalSlice"
import campaignModal from "./campaignModalSlice"
import ModalCommGroupSlice from "./ModalCommGroupSlice"
import copyLinkModalSlice from "./copyLinkModalSlice"
import dashboardSlice from "./dashboardSlice"
import listAddAccount from "./listAddAccount"
import listGroupSlice from "./listGroupSlice"
import listSlice from "./listSlice"
import listSocialProfileSlice from "./listSocialProfileSlice"
import modalAddToLists from "./modalAddToLists"
import notificationSlice from "./notificationSlice"
import profileReducer from "./profileSlice"
import publicCampaignReport from "./publicCampaignReport"
import publicListGroupSlice from "./publicListGroupSlice"
import publicListSlice from "./publicListSlice"
import searchReducer from "./searchSlice"
import slidingPanelSlice from "./slidingPanelSlice"
import socialProfileReducer from "./socialProfileSlice"
import teamSlice from "./teamSlice"
import templateReducer from "./templateSlice"
import themeModeReducer from "./themeModeSlice"
import toastReducer from "./toastSlice"
import userReducer from "./userSlice"
import verticalsReducer from "./verticalsSlice"
import { listModalFormReducer, listFormManagerReducer } from "./listModalFormSlice"
import confirmationModalSlice from "./confirmationModalSlice"
import tikTokInviteModalSlice from "./tikTokInviteLinkModalSlice"
import scoreBreakDownSlice from "./scoreBreakDownSlice"
import ModalNewTrackerSlice from "./ModalNewTrackerSlice"
import socialTrackerDownloadCSVModalSlice from "./socialTrackerDownloadCSVModalSlice"
import SocialTrackerPage from "./socialTrackerPage"
import customersPageSlice from "./customersPageSlice"
import customerUserPageSlice from "./customerUserPageSlice"
import customerSlice from "./customerSlice"
import selectCampaignModalSlice from "./selectCampaignModalSlice"
import createCustomerReducer from "./createCustomer"
import customerUserModal from "./customerUserModalSlice"
import unassignedThreadAssignModal from "./unassignedThreadAssignModal"
import unassignedThreadViewModal from "./unassignedThreadViewModal"
import communicationsPage from "./communicationsPage"
import brandManagersPermissionsModal from "./brandManagersPermissionsModal"
import commsCreateEditTemplateModal from "./commsCreateEditTemplateModal"
import contactInfoModal from "./groupAccountsContactInfoModal"
import ModalCreateMessageSlice from "./modalCreateMessageSlice"
import commEmailListViewSlice from "./commEmailListView"
import assignReviewersModal from "./assignReviewersModalSlice"
import permissionsToggle from "./permissionsToggleSlice"
import modalCreateDeliverable from "./ModalCreateDeliverable"

export const store = configureStore({
  reducer: {
    breadcrumbs: breadcrumbSlice,
    brandManagersPermissionsModal,
    campaignDeliverableContentModal,
    campaignDeliverableMetricModal,
    campaignDeliverableModal,
    campaignModal,
    campaignDeliverableAssignReviewerModal,
    campaignPage: campaign,
    commGroupModal: ModalCommGroupSlice,
    commsCreateEditTemplateModal,
    confirmationModal: confirmationModalSlice,
    copyLinkModal: copyLinkModalSlice,
    communicationsPage,
    customer: customerSlice,
    customers: customersPageSlice,
    dashboard: dashboardSlice,
    socialTrackerDownloadCSVModal: socialTrackerDownloadCSVModalSlice,
    list: listSlice,
    listAddAccount,
    listFormManager: listFormManagerReducer,
    listGroup: listGroupSlice,
    listModalForm: listModalFormReducer,
    listSocialProfile: listSocialProfileSlice,
    modalAddToLists,
    modalCreateDeliverable,
    notifications: notificationSlice,
    profile: profileReducer,
    publicCampaignReport,
    publicList: publicListSlice,
    publicListGroup: publicListGroupSlice,
    search: searchReducer,
    slidingPanels: slidingPanelSlice,
    socialProfile: socialProfileReducer,
    team: teamSlice,
    template: templateReducer,
    themeMode: themeModeReducer,
    tikTokInviteModalSlice,
    toasts: toastReducer,
    user: userReducer,
    unassignedTreadAssignModal: unassignedThreadAssignModal,
    unassignedTreadViewModal: unassignedThreadViewModal,
    verticals: verticalsReducer,
    scoreBreakDown: scoreBreakDownSlice,
    modalNewTracker: ModalNewTrackerSlice,
    socialTrackerPage: SocialTrackerPage,
    selectCampaignModalSlice,
    createCustomer: createCustomerReducer,
    customerUserModal,
    customerUserPageSlice,
    contactInfoModal,
    ModalCreateMessageSlice,
    commEmailListViewSlice,
    assignReviewersModal,
    permissionsToggle,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})

// TODO: In time, it may become necessary to separate subscriptions from the
// root state
// eslint-disable-next-line no-undef
const body = document.getElementsByTagName("body")[0]
let previousTheme: "light" | "dark" = store.getState().themeMode.theme

// Set initial body theme class
body.classList.add(previousTheme)

store.subscribe(() => {
  // Get the current state
  const currentTheme: "light" | "dark" = store.getState().themeMode.theme

  // Check if the theme has changed
  if (currentTheme !== previousTheme) {
    // Update local storage
    StorageUtil.setLocalStorageVal("theme", currentTheme)

    // Update body theme class
    body.classList.add(currentTheme)
    body.classList.remove(previousTheme)

    // Update the previous theme to the current theme
    previousTheme = currentTheme
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
