import React from "react"

import { useTranslation } from "react-i18next"
import Button from "../Button"
import EntityInfoRow from "../EntityInfoRow"

import "./post-preview.sass"
import { useDispatch, useSelector } from "../../state/hooks"
import { UserCardListRow } from "../CampaignTabs/Tabs/CampaignOverviewDetails/UserListCards/UserListCard"
import { setAssignReviewerModalOpen } from "../../state/campaignDeliverableAssignReviewerModalSlice"

type Props = {
  assignedUsers: Array<UserCardListRow>,
}

function AssignReviewer({ assignedUsers }: Props) {
  const {
    deliverable,
  } = useSelector(({ campaignDeliverableContentModal }) => campaignDeliverableContentModal)

  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.ModalCampaignDeliverableAssignReviewerGroup" })

  const dispatch = useDispatch()

  if (!deliverable?.deliverableMedia || deliverable.deliverableMedia.length === 0) return <> </>

  return (
    <div className="cp_component_modal-campaign-deliverable-assignreviewer">
      <p className="body_large">{ translate("Current Reviewers") }</p>
      {
        assignedUsers.length > 0 ? assignedUsers.map((row) => (
          <div className="user-row">
            <EntityInfoRow
              key={ row.id }
              name={ row.name }
              subInfo={ row.company }
              avatarSrc={ row.logoUrl }
            />
          </div>
        )) : (
          <div className="cp_component_modal-campaign-deliverable-assignreviewer-body">
            <div>
              <span>{ translate("NoReviewerGroupAssigned") }</span>
            </div>
            <Button
              className="cp_btn_component_highlight"
              label={ translate("Assign Reviewer Group") }
              isPrimary={ false }
              isEnabled={ true }
              variant="text"
              onClick={ async () => dispatch(setAssignReviewerModalOpen(true)) }
            />
          </div>
        )
      }
    </div>
  )
}
export default AssignReviewer
