import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import defaults from "../../config/defaults"

const primmLink = createHttpLink({
  uri: "/api/graphql",
  credentials: "same-origin",
})

const campaignPortalGqlLink = createHttpLink({
  uri: "/api-v2/graphql",
  credentials: "same-origin",
})

const combinedLink = ApolloLink.split(
  (operation) => operation.getContext().apiVersion === "v2",
  campaignPortalGqlLink,
  primmLink,
)

const platformLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    platform: defaults.TIN.APP.platformSignature,
  },
}))

const appApolloClient = new ApolloClient({
  link: platformLink.concat(combinedLink),
  cache: new InMemoryCache(),
})

export default appApolloClient
