import * as React from "react"
import { useTranslation } from "react-i18next"
import {
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material"
import { useSearchParams } from "react-router-dom"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"

import "./campaign-account-row-context-menu.sass"
import { useDispatch, useSelector } from "../../../state/hooks"
import { Scope } from "../../../util/types"
import {
  DeliverableFragment,
  DeliverableStatus,
  Network,
} from "../../../graphql"
import IconButton from "../../IconButton"
import { openEditDeliverableModal } from "../../../state/campaignDeliverableModalSlice"
import { isSuccess } from "../../../util/apiClient"
import { deleteDeliverable, refreshCampaign } from "../../../state/campaignSlice"
import { openDeliverableContentModal } from "../../../state/campaignDeliverableContentModalSlice"
import { openConfirmationModal } from "../../../state/confirmationModalSlice"
import { pushToast } from "../../../state/toastSlice"
import { openTikTokInviteModal } from "../../../state/tikTokInviteLinkModalSlice"
import { openCopyLinkModal } from "../../../state/copyLinkModalSlice"

interface CampaignDeliverableRowContextMenuProps {
  deliverable: DeliverableFragment
}

export default function CampaignDeliverableRowContextMenu({
  deliverable,
}: CampaignDeliverableRowContextMenuProps) {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { t: translate } = useTranslation([], { keyPrefix: "component.CampaignDeliverableRowContextMenu" })
  const dispatch = useDispatch()
  const { scopes } = useSelector(({ user }) => user)
  const { campaign } = useSelector(({ campaignPage }) => campaignPage)
  const [ searchParams, setSearchParams ] = useSearchParams()

  const availableAccounts = React.useMemo(() => {
    if (!isSuccess(campaign)) return []
    return campaign.payload.campaign.campaignNetworkAccounts
  }, [ campaign ])
  if (!isSuccess(campaign)) return null

  return (
    <>
      <IconButton
        className="cp_campaign-account-row-context-menu_component-button"
        onClick={ (e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        } }
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={ anchorEl }
        open={ Boolean(anchorEl) }
        onClose={ () => setAnchorEl(null) }
        className="cp_campaign-account-row-context-menu_component-menu-popover"
      >
        <MenuList
          dense={ true }
          className="cp_campaign-account-row-context-menu_component-menu"
        >
          <MenuItem
            className="cp_campaign-account-row-context-menu_component-menu-item"
            onClick={ () => {
              setSearchParams({ deliverableId: deliverable.id })
              dispatch(openDeliverableContentModal({
                // Note: not sure why, but manually casting deliverable prevents the app from freezing
                deliverable: deliverable as DeliverableFragment,
                campaign: campaign.payload.campaign,
                onClose: async (edited: boolean) => {
                  searchParams.delete("deliverableId")
                  setSearchParams(searchParams)
                  if (edited) await dispatch(refreshCampaign())
                },
              }))
              setAnchorEl(null)
            } }
          >
            <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
              { translate("View") }
            </ListItemText>
          </MenuItem>
          { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) ? (
            <MenuItem
              onClick={ () => {
                dispatch(
                  openEditDeliverableModal({
                    deliverable,
                    socialAccounts: availableAccounts,
                    updateCallback: () => dispatch(refreshCampaign()),
                  }),
                )
                setAnchorEl(null)
              } }
              className="cp_campaign-account-row-context-menu_component-menu-item"
            >
              <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                { translate("Edit") }
              </ListItemText>
            </MenuItem>
          ) : null }
          { scopes.includes(Scope.CAMPAIGN_MANAGEMENT) ? (
            <MenuItem
              className="cp_campaign-account-row-context-menu_component-menu-item"
              onClick={ () => {
                dispatch(openConfirmationModal({
                  onConfirm: () => dispatch(deleteDeliverable({
                    variables: { id: deliverable.id },
                    onSuccess: () => {
                      dispatch(refreshCampaign())
                      dispatch(pushToast({
                        message: translate("Delete Success!"),
                        type: "success",
                      }))
                    },
                  })),
                  title: translate("Delete Deliverable"),
                  subtext: translate("Are you sure you want to delete this deliverable?"),
                }))
                setAnchorEl(null)
              } }
            >
              <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                { translate("Delete") }
              </ListItemText>
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </MenuItem>
          ) : null }
          { scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
          && scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
          && deliverable.contentStatus === DeliverableStatus.Finalized
          && deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
          && !deliverable.tiktokTcmOrder?.tiktokTcmCampaign?.inviteUrl?.address
            ? (
              <MenuItem
                className="cp_campaign-account-row-context-menu_component-menu-item"
                onClick={ () => {
                  if (!deliverable) return
                  dispatch(openTikTokInviteModal({
                    deliverable,
                    onGenerateLink: () => dispatch(refreshCampaign()),
                  }))
                  setAnchorEl(null)
                }
              }
              >
                <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                  { translate("Create TTCM Invite Link") }
                </ListItemText>
              </MenuItem>
            ) : null }
          { scopes.includes(Scope.CAMPAIGN_MANAGEMENT)
          && scopes.includes(Scope.TCM_CAMPAIGN_MANAGEMENT)
          && (deliverable.contentStatus === DeliverableStatus.Finalized || deliverable.contentStatus === DeliverableStatus.Live)
          && deliverable.campaignNetworkAccount.socialAccount.network === Network.Tiktok
          && deliverable.tiktokTcmOrder?.tiktokTcmCampaign?.inviteUrl?.address
            ? (
              <MenuItem
                className="cp_campaign-account-row-context-menu_component-menu-item"
                onClick={ () => {
                  if (!deliverable.tiktokTcmOrder?.tiktokTcmCampaign.inviteUrl?.address) return
                  dispatch(openCopyLinkModal({
                    link: deliverable.tiktokTcmOrder.tiktokTcmCampaign.inviteUrl.address,
                    title: translate("Share TTCM Invite Link"),
                    subtitle: translate("Copy the invite link for the TTCM order."),
                  }))
                  setAnchorEl(null)
                }
              }
              >
                <ListItemText className="cp_campaign-account-row-context-menu_component-menu-item-label">
                  { translate("Copy TTCM Invite Link") }
                </ListItemText>
              </MenuItem>
            ) : null }
        </MenuList>
      </Menu>
    </>
  )
}
